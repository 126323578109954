import React from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'gatsby'

const PageNotFound = ({ className }) => {
  return (
    <div className={className}>
      <div className="content">
        <h1>
          Sorry, we couldn't find this page&nbsp;
          <span role="img" aria-label="person shrugging">
            🤷‍♂️
          </span>
        </h1>
        <p className="desc">
          <Link to="/">
            <span role="img" aria-label="pointing backwards">
              👈
            </span>
            &nbsp; Go back to the frontpage
          </Link>
        </p>
      </div>
    </div>
  )
}

export default styled(PageNotFound)(
  ({ theme }) => css`
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
    display: flex;

    h1 {
      font-size: 5rem;
      margin-bottom: 1rem;
    }

    .desc a {
      font-size: 3rem;
      color: black;
      text-decoration: underline;
    }
  `
)
